import * as React from 'react';
import { Dropdown, Icon, Input, Menu, Popup, Message } from 'semantic-ui-react'
import { User } from './User';
import { StoredSynth } from './Storage';

interface DockProps {
    onHelp?: () => void;
    onLoadSound?: (hash: string) => void;
    onSignIn: () => void;
    onSignOut: () => void;
    onPlay: () => void;
    onStop?: () => void;
    onAdd?: (type: string) => void;
    onSave?: () => void;
    pageUrl: string;
    isSynthPlaying: boolean;
    enableSignIn: boolean;
    user: User;
    userModels: Array<StoredSynth>;
}

export class Dock extends React.Component<DockProps> {
    constructor(props: DockProps) {
        super(props);
    }

    renderMySoundsButton() {
        if (!this.props.enableSignIn || !this.props.user.isSignedIn()) {
            return (
                <React.Fragment />
            );
        }

        let sounds = [...this.props.userModels].sort((a: StoredSynth, b: StoredSynth) => {
            return a.created_time.getTime() - b.created_time.getTime();
        })

        let items = sounds.map((synth) => {
            let date = synth.created_time.toLocaleDateString();
            let name = synth.name == null ? 'Untitled' : synth.name;
            return (
                <Dropdown.Item
                    key={synth.stored_id}
                    onClick={() => { this.props.onLoadSound(synth.hash) }}
                >
                    <i>{name}</i> - {date}
                </Dropdown.Item>
            )
        });

        return (
            <Dropdown
                item
                upward
                trigger={<><Icon name='user' />My Sounds</>}
                icon={null}
            >
                <Dropdown.Menu>
                    <Dropdown.Header>Sounds You have saved:</Dropdown.Header>
                    {items}
                    <Dropdown.Divider />
                    <Dropdown.Item as='a' onClick={this.props.onSave}>Save This Sound</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );        
    }

    renderUserButton() {
        if (!this.props.enableSignIn) {
            return (
                <React.Fragment />
            );
        }
        if (!this.props.user.isSignedIn()) {
            return (
                <Menu.Item as='a' onClick={this.props.onSignIn}>
                    <Icon name='user' />
                    Sign In
                </Menu.Item>
            );
        }
        return (
            <Dropdown
                item
                upward
                trigger={<><Icon name='user' />{this.props.user.displayName()}</>}
                icon={null}
            >
                <Dropdown.Menu>
                    <Dropdown.Header>Hello, {this.props.user.displayName()}</Dropdown.Header>
                    <Dropdown.Item onClick={this.props.onSignOut}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    render() {
        let onAdd = this.props.onAdd;
        let mySoundsButton = this.renderMySoundsButton();
        let userButton = this.renderUserButton();

        let shareButton = (
            <Menu.Item as='a'>
                <Icon name='share' />
                Share
            </Menu.Item>
        )
        return (
            <Menu icon='labeled' inverted style={{ margin: '0' }}>
                <Menu.Item
                    as='a'
                    onClick={this.props.isSynthPlaying ? this.props.onStop : this.props.onPlay}>
                    <Icon
                        name={this.props.isSynthPlaying ? 'pause' : 'play'}
                        color={this.props.isSynthPlaying ? 'green' : null}
                    />
                    {this.props.isSynthPlaying ? 'Pause' : 'Play'}
                </Menu.Item>
                <Dropdown
                    item
                    upward
                    trigger={<><Icon name='plus' />Add</>}
                    icon={null}
                >
                    <Dropdown.Menu>
                        <Dropdown.Header>Add a new component</Dropdown.Header>
                        <Dropdown.Item onClick={() => onAdd('oscillator')}>Oscillator</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('lfo')}>Low Frequency Oscillator</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('vcf')}>Low Pass Filter</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('vca')}>Volume</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('note_in')}>Note Input</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('reverb')}>Reverb</Dropdown.Item>
                        <Dropdown.Item onClick={() => onAdd('envelope')}>Envelope</Dropdown.Item>
                        <Dropdown item text='Utilities'>
                            <Dropdown.Menu>
                                <Dropdown.Header>Utilities</Dropdown.Header>
                                <Dropdown.Item onClick={() => onAdd('add')}>Add</Dropdown.Item>
                                <Dropdown.Item onClick={() => onAdd('multiply')}>Multiply</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                <Menu.Item as='a' onClick={this.props.onSave}>
                    <Icon name='file outline' />
                    Save
                </Menu.Item>
                {mySoundsButton}
                <Popup trigger={shareButton} hoverable position='top center'>
                    <Message warning>
                        Remember to save before sharing!
                    </Message>
                    <Input
                        id="pageurl"
                        action={{
                            className: 'clipboardTrigger',
                            color: 'green',
                            labelPosition: 'right',
                            icon: 'copy',
                            content: 'Copy',
                            'data-clipboard-target': '#pageUrl',
                        }}
                        defaultValue={this.props.pageUrl}
                        style={{ width: '350px' }}
                    />
                </Popup>
                {/*
                    NB working around semantic/fomantic here which doesn't apply a CSS rule:
                    .ui.labeled.icon.menu>.item>.icon:not(.dropdown)
                    to the icons in the submenu
                */}
                <Menu.Menu position='right' className="icon ui labeled inverted">
                    <Menu.Item id='helpButton' as='a' onClick={this.props.onHelp}>
                        <Icon name='help' />
                        Help
                    </Menu.Item>
                    {userButton}
                </Menu.Menu>
            </Menu >
        );
    }
}