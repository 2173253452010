import { AnyAudioContext } from "tone/build/esm/core/context/AudioContext";
import * as Tone from "tone";
// @ts-ignore
import ADSR_WORKLET_SOURCE from 'raw-loader!./AdsrAudioWorklet';
// @ts-ignore
import TEST_WORKLET_SOURCE from 'raw-loader!./TestAudioWorklet';
import { GenericSynthNodeModel } from "./SynthNodeModel";

/*
* Typescript doesn't seem to have these browser builtins for some reason...
*/
declare class AudioWorklet { }
declare class Blob {
    constructor(code: string[], opts: { type: string });
}
declare class URL {
    static createObjectURL: (b: Blob) => string;
}


export class AudioWorkletFactory {
    _context: AnyAudioContext;

    constructor(context: AnyAudioContext) {
        this._context = context;
    }

    buildEnvelopeCluster(model: GenericSynthNodeModel) {
        let inNode = new Tone.Gain();
        let outNode = new Tone.Gain();

        const blobUrl = URL.createObjectURL(new Blob([ADSR_WORKLET_SOURCE], { type: 'text/javascript' }));
        let updateParams = (worklet: any, model: GenericSynthNodeModel) => {
            worklet.parameters.get('attack').setValueAtTime(model.getParam('attack'), 0);
            worklet.parameters.get('decay').setValueAtTime(model.getParam('decay'), 0);
            worklet.parameters.get('sustain').setValueAtTime(model.getParam('sustain'), 0);
            worklet.parameters.get('release').setValueAtTime(model.getParam('release'), 0);
        }
        // Register the processor
        let name = 'adsrAudioWorklet';
        this._context.addAudioWorkletModule(blobUrl, name).then(() => {
            let worklet = this._context.createAudioWorkletNode(name, {});
            worklet.onprocessorerror = (e: any) => { console.log(e) };
            updateParams(worklet, model);
            inNode.chain(worklet, outNode);
            model.registerListener({
                paramChanged: (_: any) => {
                    updateParams(worklet, model);
                }
            });
        });



        return {
            inputs: {
                'Gate': inNode,
            },
            outputs: {
                'Out': outNode,
            }
        };
    }
}