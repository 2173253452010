import styled from '@emotion/styled';
import * as _ from 'lodash';
import { DiagramEngine, DefaultPortLabel } from '@projectstorm/react-diagrams';
import * as React from 'react';

import { GenericSynthNodeModel } from './SynthNodeModel';
import { SynthPortModel } from './SynthPortModel';

namespace S {
	export const Node = styled.div<{ background: string; selected: boolean }>`
		background-color: ${p => p.background};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${p => (p.selected ? 'rgb(0,192,255)' : 'black')};
	`;

	export const Title = styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: nowrap;
		justify-items: center;
	`;

	export const TitleName = styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`;

	export const Ports = styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`;

	export const Params = styled.div`
		display: flex;
		padding: 5px 5px;
		text-align: center;
		background-image: linear-gradient(rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.28));
	`;

	export const PortsContainer = styled.div`
		flex-grow: 1;
		display: flex;
		padding: 5px 0px;
		flex-direction: column;
		&:first-of-type {
			margin-right: 10px;
		}
		&:only-child {
			margin-right: 0px;
		}
	`;
}


interface OscillatorParamsWidgetProps {
	model: GenericSynthNodeModel;
}

interface OscParamsWidgetState {
	frequency: number;
}

export class OscillatorParamsWidget extends React.Component<OscillatorParamsWidgetProps, OscParamsWidgetState> {
	constructor(props: OscillatorParamsWidgetProps) {
		super(props);
		let model = props.model;
		this.state = {
			frequency: model.getParam('frequency'),
		}
		model.registerListener({
			paramChanged: (e) => {
				this.setState({ frequency: model.getParam('frequency') })
			}
		});
	}
	render() {
		let frequencyPortLinks = this.props.model.getPort('Frequency').getLinks();
		if (Object.keys(frequencyPortLinks).length > 0) {
			return (
				<React.Fragment />
			)
		}
		return (
			<S.Params>
				Frequency: {this.state.frequency.toFixed(1)} Hz
			</S.Params>
		);
	}
}


interface LfoParamsWidgetProps {
	model: GenericSynthNodeModel;
}

interface LfoParamsWidgetState {
	frequency: number;
}

export class LfoParamsWidget extends React.Component<LfoParamsWidgetProps, LfoParamsWidgetState> {
	constructor(props: LfoParamsWidgetProps) {
		super(props);
		let model = props.model;
		this.state = {
			frequency: model.getParam('frequency'),
		}
		model.registerListener({
			paramChanged: (e) => {
				this.setState({ frequency: model.getParam('frequency') })
			}
		});
	}
	render() {
		let frequencyPortLinks = this.props.model.getPort('Frequency').getLinks();
		if (Object.keys(frequencyPortLinks).length > 0) {
			return (
				<React.Fragment />
			)
		}

		return (
			<S.Params>
				Frequency: {this.state.frequency.toFixed(1)} Hz
			</S.Params>
		);
	}
}


interface VcfParamsWidgetProps {
	model: GenericSynthNodeModel;
}

interface VcfParamsWidgetState {
	cutoff: number;
}

export class VcfParamsWidget extends React.Component<VcfParamsWidgetProps, VcfParamsWidgetState> {
	constructor(props: VcfParamsWidgetProps) {
		super(props);
		let model = props.model;
		this.state = {
			cutoff: model.getParam('cutoff'),
		}
		model.registerListener({
			paramChanged: (e) => {
				this.setState({ cutoff: model.getParam('cutoff') })
			}
		});
	}
	render() {
		let frequencyPortLinks = this.props.model.getPort('Cutoff').getLinks();
		if (Object.keys(frequencyPortLinks).length > 0) {
			return (
				<React.Fragment />
			)
		}

		return (
			<S.Params>
				Cutoff: {this.state.cutoff.toFixed(1)} Hz
			</S.Params>
		);
	}
}


interface VcaParamsWidgetProps {
	model: GenericSynthNodeModel;
}

interface VcaParamsWidgetState {
	level: number;
}

export class VcaParamsWidget extends React.Component<VcaParamsWidgetProps, VcaParamsWidgetState> {
	constructor(props: VcaParamsWidgetProps) {
		super(props);
		let model = props.model;
		this.state = {
			level: model.getParam('level'),
		}
		model.registerListener({
			paramChanged: (e) => {
				this.setState({ level: model.getParam('level') })
			}
		});
	}
	render() {
		let frequencyPortLinks = this.props.model.getPort('Volume').getLinks();
		if (Object.keys(frequencyPortLinks).length > 0) {
			return (
				<React.Fragment />
			)
		}

		return (
			<S.Params>
				Gain: {(this.state.level * 100).toFixed(0)}%
			</S.Params>
		);
	}
}

interface SynthNodeProps {
	node: GenericSynthNodeModel;
	engine: DiagramEngine;
}

interface SynthNodeWidgetState {
	params: any;
}

/**
 * Synth node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export class SynthNodeWidget extends React.Component<SynthNodeProps> {
	constructor(props: SynthNodeProps) {
		super(props);
		this.state = {
			params: {},
		}

		let model = this.props.node;
		this.state = {
			params: model.getAllParams(),
		}
		model.registerListener({
			paramChanged: (_: any) => {
				this.setState({
					params: model.getAllParams(),
				});
			}
		});
	}

	generatePort = (port: SynthPortModel) => {
		return <DefaultPortLabel engine={this.props.engine} port={port} key={port.getID()} />;
	};

	render() {
		let model = this.props.node;
		let title = model.getOptions().name;

		if (model.getType() == 'multiply') {
			title = 'x' + model.getParam('factor').toFixed(1);
		}
		if (model.getType() == 'add') {
			let base = model.getParam('base');
			title = ((base >= 0) ? '+' : '') + base.toFixed(1);
		}

		return (
			<S.Node
				data-synth-node-name={model.getOptions().name}
				selected={model.isSelected()}
				background={model.getOptions().color} >
				<S.Title>
					<S.TitleName>{title}</S.TitleName>
				</S.Title>
				{this.props.children}
				<S.Ports>
					<S.PortsContainer>{_.map(model.getInPorts(), this.generatePort)}</S.PortsContainer>
					<S.PortsContainer>{_.map(model.getOutPorts(), this.generatePort)}</S.PortsContainer>
				</S.Ports>
			</S.Node >
		);
	}
}