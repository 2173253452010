import * as webmidi from "webmidi";
import { Input, WebMidi } from 'webmidi';

export class MidiInterface {
    _midiInput?: Input;
    _lastNote: number;

    constructor(webmidi: WebMidi) {
        let updateInput = () => { this._updateMidiInput(); };

        webmidi.enable(function (err) {
            if (!err) {
                webmidi.addListener('connected', (e: webmidi.WebMidiEventConnected) => {
                    updateInput();
                });
                webmidi.addListener('disconnected', (e: webmidi.WebMidiEventDisconnected) => {
                    updateInput();
                });
                updateInput();
            }
        });

        this._lastNote = 60;  // Middle C
    }

    _updateMidiInput() {
        if (webmidi.inputs.length > 0) {
            this._midiInput = webmidi.inputs[0];
            this._midiInput.addListener('noteon', "all", (e) => {
                this._lastNote = e.note.number;
            });
        }
    }

    addNoteOnListener(f: (note: number) => void) {
        if (this._midiInput) {
            this._midiInput.addListener('noteon', "all", (e) => {
                f(e.note.number);
            });
        }
    }

    addNoteOffListener(f: (note?: number) => void) {
        if (this._midiInput) {
            this._midiInput.addListener('noteoff', "all", (e) => {
                f(e.note.number);
            });
        }
    }

    getLastNote() {
        return this._lastNote;
    }
}

window.webmidi = webmidi;  // For debugging
