import * as firebase from 'firebase/app';
import 'firebase/analytics';


export class Analytics {
    private fbAnalytics: any;

    constructor() {
        this.fbAnalytics = firebase.analytics();
    }

    logPlayEvent() {
        this.fbAnalytics.logEvent('play');
    }

    logSaveEvent(hash: string) {
        this.fbAnalytics.logEvent('save', {
            synth: hash,
        });
    }

    logHelpEvent() {
        this.fbAnalytics.logEvent('help_view');
    }
}