import * as ClipboardJS from 'clipboard';
import * as firebase from 'firebase/app';
import { ZoomCanvasAction } from '@projectstorm/react-canvas-core/';
import createEngine from '@projectstorm/react-diagrams/';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'fomantic-ui-css/semantic.min.css';

import { Analytics } from './Analytics.ts';
import { App } from './App.tsx';
import { Base64ToHex } from './HashUtils.ts';
import { FirestoreStorage } from './Storage.ts';
import { SynthDeleteItemsAction } from './SynthDeleteItemsAction.ts';
import { SynthLinkFactory } from './SynthLinkFactory.tsx';
import { SynthNodeFactory } from './SynthNodeFactory.tsx';
import { SynthPortFactory } from './SynthPortFactory.tsx';
import { LfoParamsWidget, OscillatorParamsWidget, VcaParamsWidget, VcfParamsWidget } from './SynthNodeWidget.tsx';

function registerDiagramFactories(engine) {
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('default',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('multiply',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('add',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('oscillator',
            (model) => {
                return React.createElement(OscillatorParamsWidget, { model: model });
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('lfo',
            (model) => {
                return React.createElement(LfoParamsWidget, { model: model });
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('envelope',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('vcf',
            (model) => {
                return React.createElement(VcfParamsWidget, { model: model });
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('vca',
            (model) => {
                return React.createElement(VcaParamsWidget, { model: model });
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('note_in',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('reverb',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );
    engine.getNodeFactories().registerFactory(
        new SynthNodeFactory('out',
            (model) => {
                return React.createElement(React.Fragment);
            })
    );

    engine.getLinkFactories().registerFactory(
        new SynthLinkFactory()
    );

    engine.getPortFactories().registerFactory(
        new SynthPortFactory()
    );
}

function onload() {
    new ClipboardJS('.clipboardTrigger');

    var firebaseConfig = {
        apiKey: "AIzaSyAFa2HZuONJxm8_KZoF68GNapTjQDP4bOc",
        authDomain: "buildsound.online",
        databaseURL: "https://synth-gist.firebaseio.com",
        projectId: "synth-gist",
        storageBucket: "synth-gist.appspot.com",
        messagingSenderId: "238970817001",
        appId: "1:238970817001:web:7d90e6354e4b7cf7e52378",
        measurementId: "G-2C87KKW5J1"
    };
    firebase.initializeApp(firebaseConfig);

    let analytics = new Analytics();

    let storage = new FirestoreStorage();

    const engine = createEngine({
        registerDefaultZoomCanvasAction: false,
        registerDefaultDeleteItemsAction: false,
    });
    engine.eventBus.registerAction(new ZoomCanvasAction({ inverseZoom: true }));
    engine.eventBus.registerAction(new SynthDeleteItemsAction());
    registerDiagramFactories(engine);
    window.engine = engine;  // exposed for debugging

    let urlHash = window.location.hash.substring(1);
    if (urlHash) {
        storage.loadModel(Base64ToHex(urlHash), engine);
    } else {
        storage.loadDefaultModel(engine);
    }

    let onSave = async (user, name) => {
        let urlHash = window.location.hash.substring(1);
        analytics.logSaveEvent(Base64ToHex(urlHash));
        await storage.saveModel(engine.model, user, name);
    }

    const qsParams = new URLSearchParams(location.search);

    let appDebugOptions = {
        forceNux: qsParams.has('forceNux'),
        enableSignIn: !qsParams.has('disableSignIn'),
    };

    let rerender = () => {
        ReactDOM.render(
            React.createElement(App, {
                analytics: analytics,
                engine: engine,
                onSave: onSave,
                onLoadSound: (hash) => { storage.loadModel(hash, engine) },
                debugOptions: appDebugOptions,
                userModelsFetcher: async (user) => { return await storage.loadUserModels(user); }
            }),
            document.getElementById('diagram'),
        );
    }

    rerender();
}

window.onload = onload;