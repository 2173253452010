import { buildNodeModel } from './SynthNodeModel';
import { SynthNodeWidget } from './SynthNodeWidget';
import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent } from '@projectstorm/react-canvas-core';
import { DefaultNodeModel, DiagramEngine } from '@projectstorm/react-diagrams';

interface WidgetFactory {
    (nodeModel: Object): React.ReactElement;
}

export class SynthNodeFactory extends AbstractReactFactory<DefaultNodeModel, DiagramEngine> {
    _type: string;
    _paramsWdigetFactory: WidgetFactory;

    constructor(type: string, paramsWidgetFactory: WidgetFactory) {
        super(type);
        this._type = type;
        this._paramsWdigetFactory = paramsWidgetFactory;
    }

    generateReactWidget(event: { model: any }): JSX.Element {
        return (
            <SynthNodeWidget engine={this.engine} node={event.model} >
                {this._paramsWdigetFactory(event.model)}
            </SynthNodeWidget>
        );
    }

    generateModel(event: GenerateModelEvent) {
        return buildNodeModel(this._type);
    }
}
