import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { sha256 } from 'js-sha256';
import { DiagramModel } from '@projectstorm/react-diagrams/';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';

import { HexToUrlSafeBase64 } from './HashUtils';
import { User } from './User';

export interface StoredSynth {
    stored_id: string,
    hash: string,
    synth: Object,
    graph_version: string,
    created_time: Date,
    user?: User,
    name?: string,
}

export class FirestoreStorage {
    _db: firebase.firestore.Firestore;

    constructor() {
        this._db = firebase.firestore();
    }

    async saveModel(model: DiagramModel, user: User, name?: string) {
        let serialized = JSON.stringify(model.serialize());
        let sha = sha256(serialized);
        let record = {
            hash: sha,
            synth: serialized,
            graph_version: '0.1.0',
            created: (new Date().getTime()),
        };
        if (user.isSignedIn()) {
            // @ts-ignore
            record.user = user.id();
        }
        if ((name != null) && name != 'Untitled') {
            // @ts-ignore
            record.name = name;
        }
        await this._db.collection('synths').add(record);

        // TODO pull this location hash functionality out
        console.log(sha);
        // @ts-ignore
        window.location.hash = HexToUrlSafeBase64(sha);
    }

    // There's no natively-exposed selection change event at the model level, so we create one here
    // by triggering from each of the node selection events.
    _configureModelEventHandlers(model: DiagramModel) {
        model.registerListener({
            'nodesUpdated': (e) => {
                if (e.isCreated) {
                    e.node.registerListener({
                        'selectionChanged': (ev) => {
                            model.fireEvent({ node: e.node }, 'selectionChanged');
                        }
                    });
                }
                model.fireEvent({ node: e.node }, 'selectionChanged');
            }
        })
    }

    async loadModel(sha: string, engine: DiagramEngine) {
        let model = new DiagramModel();
        engine.setModel(model);
        this._configureModelEventHandlers(model);
        engine.fireEvent({}, 'modelChanged');

        console.log('loading hash == ' + sha);

        let querySnapshot = await this._db.collection("synths").where('hash', '==', sha).get();
        
        querySnapshot.forEach((doc) => {
            let s = JSON.parse(doc.data().synth);
            engine.getModel().deserializeModel(s, engine);

            // We do this here because the 'nodesUpdated' event handler doesn't trigger
            // on deserialization.
            engine.getModel().getNodes().forEach(n => n.registerListener({
                selectionChanged: (e) => {
                    engine.getModel().fireEvent({ node: n }, 'selectionChanged');
                }
            }));
        });
        // Unfortunately, a race condition in RD means that links won't render properly if we
        // call repaint immediately, so use a timeout.
        setTimeout(() => { engine.repaintCanvas() }, 0);

        // @ts-ignore
        window.location.hash = HexToUrlSafeBase64(sha);

        return model;
    }

    async loadUserModels(user: User): Promise<Array<StoredSynth>> {
        if (!user.isSignedIn()) {
            return [];
        }
        let querySnapshot = await this._db.collection("synths").where('user', '==', user.id()).get();
        return querySnapshot.docs.map((doc) => {         
            let d = doc.data();
            let date = new Date();
            date.setTime(d.created);
            let synth: StoredSynth = {
                stored_id: doc.id,
                hash: d.hash,
                synth: JSON.parse(d.synth),
                graph_version: d.graph_version,
                created_time: date,
                user: user,
                name: d.name,
            }
            return synth;
        });
    }

    loadDefaultModel(engine: any) {
        let model = new DiagramModel();
        this._configureModelEventHandlers(model);

        let mkModel = (type: string) => engine.getFactoryForNode(type).generateModel();

        const osc = mkModel('oscillator');
        osc.setPosition(100, 100);
        osc.setFrequency(55.0);
        const vca = mkModel('vca');
        vca.setPosition(500, 100);
        const vcf = mkModel('vcf');
        vcf.setPosition(270, 200);
        const lfo = mkModel('lfo');
        lfo.setPosition(270, 350);
        lfo.setFrequency(2.0);
        const out = mkModel('out');
        out.setPosition(700, 100);

        let links = [
            osc.ports.Saw.link(vcf.getPorts()['Audio In']),
            vcf.getPorts()['Audio Out'].link(vca.getPorts()['Audio In']),
            lfo.ports.Out.link(vca.getPorts()['Volume']),
            vca.getPorts()['Audio Out'].link(out.getPorts()['In']),
        ];

        model.addAll(osc, vca, vcf, lfo, out);
        links.forEach(l => model.addLink(l));

        engine.setModel(model);
    }
}