import * as firebase from 'firebase/app';

export interface User {
    displayName: () => string;
    isSignedIn: () => boolean;
    id: () => string | null;
}

export class SignedOutUser implements User {
    displayName = () => 'Signed Out User';

    isSignedIn = () => {
        return false;
    }

    id(): null {
        return null;
    };
}

export class FirebaseUser implements User {
    private _user: firebase.User;

    constructor(user: firebase.User) {
        this._user = user;
    }

    displayName = () => {
        return this._user.displayName;
    }

    isSignedIn = () => {
        return true;
    }

    id = () => {
        return this._user.uid;
    }
}