import { DefaultNodeModel, DiagramModel, NodeModel, DefaultPortModel } from '@projectstorm/react-diagrams/';
import * as Tone from "tone";
import { MidiNote } from 'tone/build/esm/core/type/Units';

import { AudioWorkletFactory } from './AudioWorkletFactory';
import { MonoSynthNoteTracker } from './NoteInput';
import { MidiInterface } from './Midi';
import { GenericSynthNodeModel, AudioOutNodeModel, LfoNodeModel, OscillatorNodeModel, VcaNodeModel, VcfNodeModel } from './SynthNodeModel';
import { SynthLinkModel } from './SynthLinkModel';



function hasConnectedLinks(model: GenericSynthNodeModel, portName: string) {
    let links = model.getPort(portName).getLinks()
    return (Object.keys(links).length > 0);
}


export class ToneSynth {
    _sink: Tone.Gain;

    constructor() {
    }

    static buildOscillatorCluster(model: OscillatorNodeModel) {
        let sine = new Tone.Oscillator(model.getFrequency()).sync().start(0);
        let saw = new Tone.Oscillator(model.getFrequency(), 'sawtooth').sync().start(0);
        let square = new Tone.Oscillator(model.getFrequency(), 'square').sync().start(0);
        let add = new Tone.Add(0);

        if (!hasConnectedLinks(model, 'Frequency')) {
            add.setValueAtTime(model.getFrequency(), 0);
        }

        add.connect(sine.frequency);
        add.connect(saw.frequency);
        add.connect(square.frequency);

        model.registerListener({
            paramChanged: (_: any) => {
                if (!hasConnectedLinks(model, 'Frequency')) {
                    add.setValueAtTime(model.getFrequency(), 0);
                }
            }
        });

        return {
            inputs: {
                'Frequency': add.input,
            },
            outputs: {
                'Sine': sine,
                'Saw': saw,
                'Square': square,
            }
        };
    }

    static buildLfoCluster(model: LfoNodeModel) {
        let node = new Tone.LFO(model.getFrequency()).sync().start(0);
        let add = new Tone.Add(0);
        add.connect(node.frequency);

        if (!hasConnectedLinks(model, 'Frequency')) {
            add.setValueAtTime(model.getFrequency(), 0);
        }

        model.registerListener({
            paramChanged: (_: any) => {
                if (!hasConnectedLinks(model, 'Frequency')) {
                    add.setValueAtTime(model.getFrequency(), 0);
                }
            }
        });

        return {
            inputs: {
                'Frequency': add.input,
            },
            outputs: {
                'Out': node,
            }
        };
    }

    static buildVcfCluster(model: VcfNodeModel) {
        let node = new Tone.Filter(model.getCutoff());
        let add = new Tone.Add(0);
        add.connect(node.frequency);

        if (!hasConnectedLinks(model, 'Cutoff')) {
            add.setValueAtTime(model.getCutoff(), 0);
        }

        model.registerListener({
            paramChanged: (_: any) => {
                if (!hasConnectedLinks(model, 'Cutoff')) {
                    add.setValueAtTime(model.getCutoff(), 0);
                }
            }
        });

        return {
            inputs: {
                'Audio In': node,
                'Cutoff': add.input,
            },
            outputs: {
                'Audio Out': node,
            }
        };
    }

    static buildVcaCluster(model: VcaNodeModel) {
        let node = new Tone.Gain(model.getLevel());
        let add = new Tone.Add(0);
        add.connect(node.gain);

        if (!hasConnectedLinks(model, 'Volume')) {
            add.setValueAtTime(model.getLevel(), 0);
        }

        model.registerListener({
            paramChanged: (_: any) => {
                if (!hasConnectedLinks(model, 'Volume')) {
                    add.setValueAtTime(model.getLevel(), 0);
                }
            }
        });

        return {
            inputs: {
                'Audio In': node,
                'Volume': add,
            },
            outputs: {
                'Audio Out': node,
            }
        };
    }

    static buildNoteInCluster(model: GenericSynthNodeModel, midi: MidiInterface) {
        let node = new Tone.Signal(Tone.FrequencyClass.mtof(midi.getLastNote() as MidiNote));
        let gate = new Tone.Signal(0.0);

        let noteTracker = new MonoSynthNoteTracker();

        midi.addNoteOnListener((noteNumber) => {
            noteTracker.noteOn(noteNumber);
            node.setValueAtTime(Tone.FrequencyClass.mtof(noteTracker.getCurrentNote() as MidiNote), 0);
            if (noteTracker.notesArePlaying()) {
                gate.setValueAtTime(1.0, 0);
            }
        });

        midi.addNoteOffListener((noteNumber) => {
            noteTracker.noteOff(noteNumber);
            node.setValueAtTime(Tone.FrequencyClass.mtof(noteTracker.getCurrentNote() as MidiNote), 0);
            if (!noteTracker.notesArePlaying()) {
                gate.setValueAtTime(0.0, 0);
            }
        });

        return {
            inputs: {
            },
            outputs: {
                'Frequency': node,
                'On/Off': gate,
            }
        };
    }

    static buildReverbCluster(model: GenericSynthNodeModel) {
        let node = new Tone.Reverb(model.getParam('decay'));
        node.preDelay = model.getParam('preDelay');

        model.registerListener({
            paramChanged: (_: any) => {
                node.decay = model.getParam('decay');
                node.preDelay = model.getParam('preDelay');
            }
        });

        return {
            inputs: {
                'Audio In': node,
            },
            outputs: {
                'Audio Out': node,
            }
        };
    }

    static buildAddCluster(model: GenericSynthNodeModel) {
        let node = new Tone.Add(model.getParam('base'));

        model.registerListener({
            paramChanged: (_: any) => {
                node.setValueAtTime(model.getParam('base'), 0);
            }
        });

        return {
            inputs: {
                '#1': node,
                '#2': node,
            },
            outputs: {
                'Sum': node,
            }
        };
    }

    static buildMultiplyCluster(model: GenericSynthNodeModel) {
        let node = new Tone.Multiply(model.getParam('factor'));

        model.registerListener({
            paramChanged: (_: any) => {
                node.setValueAtTime(model.getParam('factor'), 0);
            }
        });

        return {
            inputs: {
                'In': node,
            },
            outputs: {
                'Product': node,
            }
        };
    }

    static buildAudioOutCluster(model: DefaultNodeModel, sink: Tone.Gain) {
        return {
            inputs: {
                'In': sink,
            },
            outputs: {
            }
        };
    }

    static buildClusterFromNodeModel(
        model: NodeModel,
        sink: Tone.Gain,
        midi: MidiInterface,
        workletFactory: AudioWorkletFactory) {
        switch (model.getType()) {
            case 'add':
                return this.buildAddCluster(model as GenericSynthNodeModel);
                break;
            case 'multiply':
                return this.buildMultiplyCluster(model as GenericSynthNodeModel);
                break;
            case 'oscillator':
                return this.buildOscillatorCluster(model as OscillatorNodeModel);
                break;
            case 'vcf':
                return this.buildVcfCluster(model as VcfNodeModel);
                break;
            case 'vca':
                return this.buildVcaCluster(model as VcaNodeModel);
                break;
            case 'lfo':
                return this.buildLfoCluster(model as LfoNodeModel);
                break;
            case 'envelope':
                return workletFactory.buildEnvelopeCluster(model as GenericSynthNodeModel);
                break;
            case 'out':
                return this.buildAudioOutCluster(model as AudioOutNodeModel, sink);
            case 'note_in':
                return this.buildNoteInCluster(model as GenericSynthNodeModel, midi);
                break;
            case 'reverb':
                return this.buildReverbCluster(model as GenericSynthNodeModel);
            default:
                return { inputs: {}, outputs: {} }
        }
    }

    static fromReactDiagramsModel(model: DiagramModel, midi: MidiInterface) {
        Tone.start();

        // NB to trade latency for more stability uncomment this:
        // Tone.getContext().latencyHint = 'balanced';

        let workletFactory = new AudioWorkletFactory(Tone.getContext());

        // For each diagram node, we create 'clusters' of ToneSynth nodes
        // with addressable inputs/outputs
        let clusterMap = new Map();
        let sink = new Tone.Gain();
        model.getNodes().forEach(n => {
            clusterMap.set(n.getID(), this.buildClusterFromNodeModel(n, sink, midi, workletFactory));
        });

        model.getLinks().forEach((link: SynthLinkModel) => {
            let sp = link.getSourcePort() as DefaultPortModel;
            let tp = link.getTargetPort() as DefaultPortModel;
            if (!sp || !tp) return;

            // If link is connected target->source, reverse.
            let isInPort = (p: DefaultPortModel) => {
                let n = p.getParent() as DefaultNodeModel;
                return (n.getInPorts().find((port: DefaultPortModel) => port == p) != undefined);
            };
            if (!isInPort(tp)) {
                sp = link.getTargetPort() as DefaultPortModel;
                tp = link.getSourcePort() as DefaultPortModel;
            }

            let source = clusterMap.get(sp.getNode().getID());
            let target = clusterMap.get(tp.getNode().getID());
            if (source && target && source.outputs[sp.getName()] && target.inputs[tp.getName()]) {
                let multiply = new Tone.Multiply(link.getStrength());
                link.registerListener({
                    paramChanged: (e) => {
                        multiply.setValueAtTime(link.getStrength(), 0);
                    }
                });
                source.outputs[sp.getName()].connect(multiply);
                multiply.connect(target.inputs[tp.getName()]);
            }
        });

        let synth = new ToneSynth();
        synth._sink = sink;
        return synth;
    }

    start() {
        this._sink.connect(Tone.getContext().destination);
        Tone.Transport.start();
    }

    stop() {
        Tone.Transport.stop();
        this._sink.disconnect();
    }
}

window.Tone = Tone;  // For debugging