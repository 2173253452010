import * as React from 'react';
import { Icon, Header, Modal } from 'semantic-ui-react'

interface HelpModalProps {
    open: boolean;
    onClose: () => void;
}

export class HelpModal extends React.Component<HelpModalProps> {
    render() {
        return (
            <Modal
                basic
                size='small'
                open={this.props.open}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.props.onClose}
            >
                <Header content='Tips For Getting Started' />
                <Modal.Content>
                    <p style={{ color: 'lightgreen' }}><Icon name='object group outline'></Icon><b>The Diagram</b></p>
                    <p>
                        The diagram you see is made up of "nodes" that are connected together by wires. You can click on any node to find out more about it.
                    </p>
                    <p style={{ color: 'lightgreen' }}><Icon name='code branch'></Icon><b>Sockets and Connections</b></p>
                    <p>

                        Each node has some "sockets" you can connect wires to. You can make connections between nodes by clicking on a socket and dragging your mouse to a socket on another node.
                    </p>
                    <p>
                        You can remove wires by first selecting them (hold down shift and click a wire) then hitting backspace on your keyboard.
                    </p>
                    <p style={{ color: 'lightgreen' }}><Icon name='bullhorn'></Icon><b>Making Some Noise</b></p>
                    <p>
                        You should always see a red "Audio Out" node in the diagram. Sound will start to play when you hit the "play" button, provided something that makes sound (like an oscillator) is connected to "Audio Out".
                    </p>
                    <p>
                        You can play with any midi keyboard plugged into your computer. Any "Note Input" node will listen for notes played on your keyboard.
                    </p>
                </Modal.Content>
            </Modal>
        )
    }
}