import * as React from 'react';
import { DefaultPortFactory } from '@projectstorm/react-diagrams';

import { SynthPortModel } from './SynthPortModel';

export class SynthPortFactory extends DefaultPortFactory {
    generateModel(): SynthPortModel {
        return new SynthPortModel({
            name: 'unknown',
        });
    }
}