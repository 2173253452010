import * as chroma from 'chroma-js';
const Scale = require('d3-scale');
import 'rc-input-number/assets/index.css';
import * as React from 'react';
import { BaseModel } from '@projectstorm/react-canvas-core';
import InputNumber from 'rc-input-number';
import { Button, Card, Icon, List, Sidebar as SemanticSidebar } from 'semantic-ui-react';

import { SynthLinkModel } from './SynthLinkModel';
import { GenericSynthNodeModel } from './SynthNodeModel';


interface SidebarProperties {
    selectedEntities: Array<BaseModel>,
}


interface GenericSynthNodeSidebarProps {
    model: GenericSynthNodeModel;
}

interface AddSidebarCardState {
    base: number;
}

class AddSidebarCard extends React.Component<GenericSynthNodeSidebarProps, AddSidebarCardState> {
    constructor(props: GenericSynthNodeSidebarProps) {
        super(props);
        this.state = {
            base: props.model.getParam('base'),
        }
    }

    _handleFloatParamChange(paramName: string, newValue: string) {
        let f = parseFloat(newValue);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setParam(paramName, f);
        if (paramName == 'base') {
            this.setState({ base: f });
        }
    }

    render() {
        return (
            <>
                <Card.Content>
                    Add inputs to: &nbsp;
                    <InputNumber
                        value={this.state.base}
                        onChange={(value: string) => { this._handleFloatParamChange('base', value) }}
                        precision={2}
                    />
                </Card.Content>
            </>
        );
    }
}


interface MultiplySidebarCardState {
    factor: number;
}

class MultiplySidebarCard extends React.Component<GenericSynthNodeSidebarProps, MultiplySidebarCardState> {
    constructor(props: GenericSynthNodeSidebarProps) {
        super(props);
        this.state = {
            factor: props.model.getParam('factor'),
        }
    }

    _handleFloatParamChange(paramName: string, newValue: string) {
        let f = parseFloat(newValue);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setParam(paramName, f);
        if (paramName == 'factor') {
            this.setState({ factor: f });
        }
    }

    render() {
        return (
            <>
                <Card.Content>
                    Multiply by: &nbsp;
                    <InputNumber
                        value={this.state.factor}
                        onChange={(value: string) => { this._handleFloatParamChange('factor', value) }}
                        precision={2}
                    />
                </Card.Content>
            </>
        );
    }
}


interface EnvelopeSidebarCardState {
    attack: number;
    decay: number;
    sustain: number;
    release: number;
}

class EnvelopeSidebarCard extends React.Component<GenericSynthNodeSidebarProps, EnvelopeSidebarCardState> {
    constructor(props: GenericSynthNodeSidebarProps) {
        super(props);
        this.state = {
            attack: props.model.getParam('attack'),
            decay: props.model.getParam('decay'),
            sustain: props.model.getParam('sustain'),
            release: props.model.getParam('release'),
        }
    }

    _handleFloatParamChange(paramName: string, newValue: string) {
        let f = parseFloat(newValue);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setParam(paramName, f);
        if (paramName == 'attack') {
            this.setState({ attack: f });
        }
        if (paramName == 'decay') {
            this.setState({ decay: f });
        }
        if (paramName == 'sustain') {
            this.setState({ sustain: f });
        }
        if (paramName == 'release') {
            this.setState({ release: f });
        }
    }

    render() {
        return (
            <>
                <Card.Content>
                    Attack: &nbsp;
                    <InputNumber
                        value={this.state.attack}
                        onChange={(value: string) => { this._handleFloatParamChange('attack', value) }}
                        precision={2}
                    /> &nbsp; seconds.
                </Card.Content>
                <Card.Content>
                    Decay: &nbsp;
                    <InputNumber
                        value={this.state.decay}
                        onChange={(value: string) => { this._handleFloatParamChange('decay', value) }}
                        precision={2}
                    /> &nbsp; seconds.
                </Card.Content>
                <Card.Content>
                    Sustain: &nbsp;
                    <InputNumber
                        value={this.state.sustain}
                        onChange={(value: string) => { this._handleFloatParamChange('sustain', value) }}
                        precision={2}
                        min={0}
                        max={1}
                    />
                </Card.Content>
                <Card.Content>
                    Release: &nbsp;
                    <InputNumber
                        value={this.state.release}
                        onChange={(value: string) => { this._handleFloatParamChange('release', value) }}
                        precision={2}
                    /> &nbsp; seconds.
                </Card.Content>
            </>
        );
    }
}


interface ReverbSidebarCardState {
    decay: number;
    preDelay: number;
}

class ReverbSidebarCard extends React.Component<GenericSynthNodeSidebarProps, ReverbSidebarCardState> {
    constructor(props: GenericSynthNodeSidebarProps) {
        super(props);
        this.state = {
            decay: props.model.getParam('decay'),
            preDelay: props.model.getParam('preDelay'),
        }
    }

    _handleFloatParamChange(paramName: string, newValue: string) {
        let f = parseFloat(newValue);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setParam(paramName, f);
        if (paramName == 'decay') {
            this.setState({ decay: f });
        }
        if (paramName == 'preDelay') {
            this.setState({ preDelay: f });
        }
    }

    render() {
        return (
            <>
                <Card.Content>
                    Decay: &nbsp;
                    <InputNumber
                        value={this.state.decay}
                        onChange={(value: string) => { this._handleFloatParamChange('decay', value) }}
                        precision={2}
                    /> &nbsp; seconds.
                </Card.Content>
                <Card.Content>
                    Pre Delay: &nbsp;
                    <InputNumber
                        value={this.state.preDelay}
                        onChange={(value: string) => { this._handleFloatParamChange('preDelay', value) }}
                        precision={2}
                    /> &nbsp; seconds.
                </Card.Content>
            </>
        );
    }
}


class OscillatorSidebarCard extends React.Component<GenericSynthNodeModel> {
    constructor(props: Object) {
        super(props);
        this.state = {
            frequency: props.model.getFrequency(),
        }
    }

    _handleFrequencyChange(value: string) {
        let f = parseFloat(value);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setFrequency(f);
        this.setState({ frequency: f });
    }

    render() {
        return (
            <>
                <Card.Content>
                    Frequency: &nbsp;
                    <InputNumber
                        min={1}
                        max={20000}
                        value={this.state.frequency}
                        onChange={value => { this._handleFrequencyChange(value) }}
                        precision={2}
                    />
                </Card.Content>
                <Card.Content extra>oooooooooooh</Card.Content>
            </>
        );
    }
}


class LfoSidebarCard extends React.Component<GenericSynthNodeModel> {
    constructor(props: Object) {
        super(props);
        this.state = {
            frequency: props.model.getFrequency(),
        }
    }

    _handleFrequencyChange(value: string) {
        let f = parseFloat(value);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setFrequency(f);
        this.setState({ frequency: f });
    }

    render() {
        return (
            <>
                <Card.Content>
                    Frequency: &nbsp;
                    <InputNumber
                        min={0.1}
                        max={10.0}
                        step={0.01}
                        value={this.state.frequency}
                        onChange={value => { this._handleFrequencyChange(value) }}
                        precision={2}
                    />
                </Card.Content>
                <Card.Content extra>⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘⇗⇘</Card.Content>
            </>
        );
    }
}


class VcfSidebarCard extends React.Component<GenericSynthNodeModel> {
    constructor(props: Object) {
        super(props);
        this.state = {
            cutoff: props.model.getCutoff(),
        }
    }

    _handleFrequencyChange(value: string) {
        let f = parseFloat(value);
        if (isNaN(f)) {
            return;
        }
        this.props.model.setCutoff(f);
        this.setState({ cutoff: f });
    }

    render() {
        return (
            <>
                <Card.Content>
                    Frequency: &nbsp;
                    <InputNumber
                        min={1}
                        max={20000}
                        value={this.state.cutoff}
                        onChange={value => { this._handleFrequencyChange(value) }}
                        precision={2}
                    />
                </Card.Content>
                <Card.Content extra>wub wub wub</Card.Content>
            </>
        );
    }
}


class VcaSidebarCard extends React.Component<GenericSynthNodeModel> {
    constructor(props: Object) {
        super(props);
        this.state = {
            level: props.model.getLevel(),
        }
    }

    render() {
        let handleSliderChange = (event) => {
            let level = parseFloat(event.target.value);
            if (isNaN(level)) {
                return;
            }
            this.props.model.setLevel(level);
            this.setState({ level: level });
        }

        return (
            <>
                <Card.Content>
                    Gain:
                    <input
                        type="range"
                        style={{ margin: "0 5px", display: "inline-block", verticalAlign: "middle" }}
                        onChange={handleSliderChange}
                        value={this.state.level}
                        min={0}
                        max={1.0}
                        step={0.01}
                    />
                    {(this.state.level * 100).toFixed(0)}%
                </Card.Content>
                <Card.Content extra>Turn It Up.</Card.Content>
            </>
        );
    }
}

interface LinkSidebarCardProps {
    model: SynthLinkModel;
}

class LinkSidebarCard extends React.Component<LinkSidebarCardProps> {
    constructor(props: LinkSidebarCardProps) {
        super(props);
        this.state = {
            // TODO: Right now we don't register a listener on the link to update
            // this card if the model is independently changed.
            strength: props.model.getStrength(),
        }
    }

    _handleStrengthChange = (event: { target: { value: string; }; }) => {
        let val = parseFloat(event.target.value);
        if (isNaN(val)) {
            return;
        }
        this.props.model.setStrength(val);
        this.setState({ strength: val });
    }

    render() {
        let l: SynthLinkModel = this.props.model;
        if (!l.getSourcePort() || !l.getTargetPort()) {
            return (
                <React.Fragment />
            )
        }

        let fromNode = l.getSourcePort().getNode();
        let toNode = l.getTargetPort().getNode();

        let fromColor = fromNode.getOptions().color;
        let toColor = toNode.getOptions().color;
        let fromColorDarker = chroma(fromColor).darken().hex();
        let toColorDarker = chroma(toColor).darken().hex();
        return (
            <Card fluid={true}>
                <Card.Content textAlign='center'>
                    <Card.Meta>
                        <span style={{ color: fromColorDarker, float: 'left' }}>
                            {fromNode.getOptions().name}
                            <br />
                            {l.getSourcePort().getOptions().name}
                        </span>
                        <Icon name='arrows alternate horizontal' />
                        <span style={{ color: toColorDarker, float: 'right' }}>
                            {toNode.getOptions().name}
                            <br />
                            {l.getTargetPort().getOptions().name}
                        </span>
                    </Card.Meta>
                </Card.Content>
                <Card.Content textAlign='center'>
                    Strength:
                    <input
                        type="range"
                        style={{ margin: "0 5px", display: "inline-block", verticalAlign: "middle" }}
                        value={this.state.strength}
                        min={-1.0}
                        max={1.0}
                        step={0.01}
                        onChange={event => { this._handleStrengthChange(event) }}
                    />
                    {this.state.strength >= 0.0 ? '+' : ''}
                    {this.state.strength.toFixed(1)}
                </Card.Content>
            </Card>
        );
    }
}

export class Sidebar extends React.Component<SidebarProperties> {
    constructor(props: SidebarProperties) {
        super(props);
    }

    renderNodeContent(n: GenericSynthNodeModel) {
        let options = n.getOptions();
        if (!options) {
            return (
                <span />
            );
        }

        let content = (
            <Card.Content>{options.name} has no parameters to tweak.</Card.Content>
        )
        switch (options.type) {
            case 'add':
                content = (
                    <AddSidebarCard model={n} />
                );
                break;
            case 'multiply':
                content = (
                    <MultiplySidebarCard model={n} />
                );
                break;
            case 'oscillator':
                content = (
                    <OscillatorSidebarCard model={n} />
                );
                break;
            case 'lfo':
                content = (
                    <LfoSidebarCard model={n} />
                );
                break;
            case 'envelope':
                content = (
                    <EnvelopeSidebarCard model={n} />
                );
                break;
            case 'vcf':
                content = (
                    <VcfSidebarCard model={n} />
                );
                break;
            case 'vca':
                content = (
                    <VcaSidebarCard model={n} />
                );
                break;
            case 'reverb':
                content = (
                    <ReverbSidebarCard model={n} />
                )
                break;
        }

        let deleteBtn = (
            <React.Fragment />
        );
        if (options.name != 'Audio Out') {
            deleteBtn = (
                <Card.Content>
                    <Button
                        icon
                        negative
                        size='mini'
                        floated='right'
                        onClick={() => {
                            n.remove();
                        }}
                    >
                        <Icon name='trash' />
                        Delete
                </Button>
                </Card.Content>
            );
        }

        let links = Object.values(n.getPorts()).map(p => {
            return Object.values(p.getLinks());
        }).flat();

        let linkCards = links.map(l => {
            return (
                <LinkSidebarCard model={l} key={l.getID()} />
            )
        });

        return (
            <React.Fragment key={options.id}>
                <Card
                    fluid={true}
                >
                    <Card.Content>
                        <Card.Header>{options.name}</Card.Header>
                    </Card.Content>
                    {content}
                    {deleteBtn}
                </Card>
                {linkCards}
            </React.Fragment>
        );
    }

    render() {
        const subcards =
            this.props.selectedEntities
                .filter(e => {
                    // NB this is an UGLY way of filtering non-node entities out
                    return e.hasOwnProperty('ports');
                })
                .map(e => this.renderNodeContent(e as GenericSynthNodeModel));

        let color = this.props.selectedEntities.length > 0 ?
            chroma(this.props.selectedEntities[0].options.color).darken().saturate(4).hex() : "rgb(255, 250, 255)";
        let gradColor = chroma(color).darken().hex();
        return (
            <SemanticSidebar
                visible={this.props.selectedEntities.length > 0}
                animation="overlay"
                vertical="true"
                direction="right"
                width="wide"
                style={{
                    background: "linear-gradient(" + color + "," + gradColor + ")",
                    padding: "1em",
                }}
            >
                <List relaxed>
                    <List.Item>
                        <List.Header as="h2" style={{ color: "white" }}>Tweak Here</List.Header>
                    </List.Item>
                    <List.Item>
                        <Card.Group>{subcards}</Card.Group>
                    </List.Item>
                </List>
            </SemanticSidebar >
        );
    }
}