import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { createBrowserHistory } from 'history';
import * as Cookies from 'js-cookie';
import * as React from 'react';
import { BaseModel, CanvasWidget } from '@projectstorm/react-canvas-core/';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import Joyride, { Step } from 'react-joyride';
import { toast, SemanticToastContainer } from 'react-semantic-toasts';
import { Button, Input, Modal } from 'semantic-ui-react';
import * as webmidi from 'webmidi';

import { Analytics } from './Analytics';
import { Dock } from './Dock';
import { HelpModal } from './HelpModal';
import { MidiInterface } from './Midi';
import { Sidebar } from './Sidebar';
import { StoredSynth } from './Storage';
import { ToneSynth } from './ToneSynth';
import { FirebaseUser, SignedOutUser, User } from './User';


const JOYRIDE_STEPS: Array<Step> = [
    {
        target: 'body',
        placement: 'center',
        content: (
            <>
                <h2>Welcome</h2>
                <p>
                    Welcome to the online sound creator!
                </p>
                <p>
                    This is a tool to help people build and share sounds.
                </p>
            </>
        ),
    },
    {
        target: 'body',
        placement: 'center',
        content: (
            <>
                <h2>It's Early Days</h2>
                <p>
                    This is an early preview, so expect some bugs, performance issues, and sharp edges.
                </p>
            </>
        ),
    },
    {
        disableBeacon: true,
        target: '#helpButton',
        content: (
            <>
                <h2>More Help is Here</h2>
                <p>
                    You can find lots more help to get started here. We hope you have fun!
                </p>
            </>
        ),
    },
];


interface AppDebugOptions {
    forceNux: boolean;
    enableSignIn: boolean;
}

interface AppProps {
    analytics: Analytics;
    engine: DiagramEngine;
    onSave?: (user: User, name?: string) => null;
    onLoadSound: (hash: string) => void;
    debugOptions?: AppDebugOptions;
    userModelsFetcher: (user: User) => Promise<Array<StoredSynth>>;
}

enum AppModalState {
    NothingOpen,
    SignIn,
    Help,
    Save,
}

interface AppState {
    selectedEntities: Array<BaseModel>;
    whichModalIsOpen: AppModalState,
    isSynthPlaying: boolean;
    shouldShowNux: boolean;
    user: User;
    pageUrl: string;
    userModels: Array<StoredSynth>;
    soundNameForSaving: string;
}

export class App extends React.Component<AppProps, AppState> {
    private _auth: firebase.auth.Auth;
    private _authUi?: firebaseui.auth.AuthUI;
    private _synth: any;
    private _midi: MidiInterface;
    private _unlistenToUrlHistory: () => void;

    constructor(props: AppProps) {
        super(props);

        // @ts-ignore
        this._midi = new MidiInterface(webmidi);

        this._auth = firebase.auth();

        this.state = {
            selectedEntities: props.engine.getModel().getSelectedEntities(),
            whichModalIsOpen: AppModalState.NothingOpen,            
            isSynthPlaying: false,
            shouldShowNux: props.debugOptions?.forceNux || !(Cookies.get('has-seen-nux') == 'true'),
            user: new SignedOutUser(),
            // @ts-ignore
            pageUrl: window.location.href,
            userModels: [],
            soundNameForSaving: '',
        }

        this._registerDiagramModelListeners();
        props.engine.registerListener({
            'modelChanged': () => {
                this._registerDiagramModelListeners();
            }
        });

        Cookies.set('has-seen-nux', 'true');
    }

    _registerDiagramModelListeners() {
        let model = this.props.engine.getModel();
        model.registerListener({
            'selectionChanged': () => {
                this.setState({ selectedEntities: model.getSelectedEntities() });
            }
        });

        model.registerListener({
            'nodesUpdated': () => {
                this._rebuildSynth();
            }
        });
        model.registerListener({
            'linksUpdated': (e) => {
                this._rebuildSynth();
                if (e.isCreated) {
                    e.link.registerListener({
                        'sourcePortChanged': (e) => {
                            this._rebuildSynth();
                        },
                        'targetPortChanged': (e) => {
                            this._rebuildSynth();
                        }
                    })
                }
            }
        });
    }

    componentDidMount() {
        this._auth.onAuthStateChanged((user) => {
            if (user) {
                let u = new FirebaseUser(user);
                this.setState({ user: u });
                this.props.userModelsFetcher(u).then((models) => {
                    this.setState({ userModels: models });
                });
            } else {
                this.setState({ user: new SignedOutUser() });
            }
        });

        this._authUi = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this._auth);

        if (this._authUi.isPendingRedirect()) {
            this._onSignIn();
        }

        const history = createBrowserHistory();
        this._unlistenToUrlHistory = history.listen((location: any, action: any) => {
            this.setState({
                // @ts-ignore
                pageUrl: window.location.href,
            })
        });
    }

    componentWillUnmount() {
        this._unlistenToUrlHistory();
    }

    _rebuildSynth = () => {
        if (this._synth && this.state.isSynthPlaying) {
            this._synth.stop();
        }
        this._synth = ToneSynth.fromReactDiagramsModel(this.props.engine.getModel(), this._midi);

        // Now continue if we were already playing
        if (this.state.isSynthPlaying) {
            this._synth.start();
        }
    }

    _onPlay = () => {
        this.props.analytics.logPlayEvent();
        this._rebuildSynth();
        this.setState({ isSynthPlaying: true });
        this._synth.start();
    };

    _onStop = () => {
        if (this._synth) {
            this._synth.stop();
        }
        this.setState({ isSynthPlaying: false });
    }

    _onAdd = (type: string) => {
        let engine = this.props.engine;
        let node = engine.getFactoryForNode(type).generateModel({});
        if (node == undefined) return;

        node.setPosition(800, 300);
        engine.getModel().addNode(node);
        engine.repaintCanvas();
    };

    _onSignIn = () => {
        let firebaseUiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
                    this.setState({
                        whichModalIsOpen: AppModalState.NothingOpen,
                    });
                },
            },
            // @ts-ignore
            signInSuccessUrl: window.location.href,
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            tosUrl: 'https://buildsound.online/static/terms.html',
            privacyPolicyUrl: 'https://buildsound.online/static/privacy.html',
        };

        this.setState({
            whichModalIsOpen: AppModalState.SignIn,
        }, () => {
            this._authUi.start('#firebaseui-auth-container', firebaseUiConfig as firebaseui.auth.Config);
        });
    }

    _onSignOut = () => {
        this._auth.signOut();
    }

    render() {
        return (
            <div className="app" style={{ width: '100vw', height: '100vh' }} >
                <Modal
                    basic
                    size='small'
                    open={this.state.whichModalIsOpen == AppModalState.SignIn}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    onClose={() => { this.setState({ whichModalIsOpen: AppModalState.NothingOpen }) }}
                >
                    <div id="firebaseui-auth-container" />
                </Modal>
                <Joyride
                    steps={JOYRIDE_STEPS}
                    continuous={true}
                    run={this.state.shouldShowNux}
                    showProgress={true}
                    styles={{
                        options: {
                            zIndex: 10000,
                        },
                    }}
                />
                <CanvasWidget engine={this.props.engine} className='diagram' />
                <Sidebar selectedEntities={this.state.selectedEntities} />
                <Dock
                    onSignIn={this._onSignIn}
                    onSignOut={this._onSignOut}
                    onHelp={() => {
                        this.props.analytics.logHelpEvent();
                        this.setState({ whichModalIsOpen: AppModalState.Help });
                    }}
                    onPlay={this._onPlay}
                    onStop={this._onStop}
                    onAdd={this._onAdd}
                    onSave={() => { this.setState({whichModalIsOpen: AppModalState.Save }); }}
                    onLoadSound = { this.props.onLoadSound }
                    pageUrl={this.state.pageUrl}
                    isSynthPlaying={this.state.isSynthPlaying}
                    enableSignIn={this.props.debugOptions?.enableSignIn}
                    user={this.state.user}
                    userModels={this.state.userModels}
                />
                <SemanticToastContainer position='top-right' />
                <HelpModal
                    open={this.state.whichModalIsOpen == AppModalState.Help}
                    onClose={() => { this.setState({ whichModalIsOpen: AppModalState.NothingOpen }) }}
                />
                <Modal
                    open={this.state.whichModalIsOpen == AppModalState.Save}
                >
                    <Modal.Header>Save This Sound</Modal.Header>
                    <Modal.Content>
                        Give your sound a name:
                        &nbsp;
                        <Input
                            placeholder='Untitled'
                            onChange={(e) => {
                                // @ts-ignore
                                this.setState({ soundNameForSaving: e.target.value })}
                            }
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={ () => { this.setState({ whichModalIsOpen: AppModalState.NothingOpen }) }}>
                            Cancel
                        </Button>
                        <Button color='green' onClick={async () => {
                            await this.props.onSave(this.state.user, this.state.soundNameForSaving);
                            let models = await this.props.userModelsFetcher(this.state.user);
                            this.setState({
                                userModels: models,
                                whichModalIsOpen: AppModalState.NothingOpen
                            });
                            toast({
                                type: 'success',
                                icon: 'share',
                                title: 'Synth Saved',
                                description: 'Your synth has been saved to the cloud. You can access it again at this page\'s URL.',
                                animation: 'bounce',
                                time: 3000,
                            });
                        }}>
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
                <div
                    style={{
                        position: 'fixed',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        bottom: 0,
                        color: 'gray',
                        zIndex: 20000,
                        fontSize: '10px',
                    }}>
                    @HoodjiMcWaadji Ltd
                    &nbsp;
                    <a style={{ color: 'gray' }} target="_blank" href="/static/terms.html">Terms</a>
                    &nbsp;
                    <a style={{ color: 'gray' }} target="_blank" href="/static/privacy.html">Privacy</a>
                </div>
            </div>
        )
    }
}

// @ts-ignore
window.Cookies = Cookies;  // For debugging