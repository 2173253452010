import * as React from 'react';
import { DefaultLinkFactory, DefaultLinkModel, DefaultLinkWidget } from '@projectstorm/react-diagrams';

import { SynthLinkModel } from './SynthLinkModel';

export class SynthLinkFactory extends DefaultLinkFactory {
    constructor() {
        super('default');
    }

    generateModel(): SynthLinkModel {
        return new SynthLinkModel();
    }

    generateReactWidget(event): JSX.Element {
        return <DefaultLinkWidget link={event.model} diagramEngine={this.engine} />;
    }
}