var forge = require('node-forge');

export function HexToUrlSafeBase64(hex: string) {
    return forge.util.encode64(forge.util.hexToBytes(hex))
        .replace(/\+/g, '-').replace(/\//g, '_');
}

export function Base64ToHex(b64: string) {
    b64 = b64.replace(/\-/g, '+').replace(/\_/g, '/');
    return forge.util.bytesToHex(forge.util.decode64(b64));
}