import { Action, ActionEvent, InputType } from '@projectstorm/react-canvas-core';
import { KeyboardEvent } from 'react';

import { SynthNodeModel } from './SynthNodeModel.tsx';

export interface SynthDeleteItemsActionOptions {
    keyCodes?: number[];
}

export class SynthDeleteItemsAction extends Action {
    constructor(options: SynthDeleteItemsActionOptions = {}) {
        options = {
            keyCodes: [46, 8],
            ...options
        };
        super({
            type: InputType.KEY_DOWN,
            fire: (event: ActionEvent<KeyboardEvent>) => {
                if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
                    _.forEach(this.engine.getModel().getSelectedEntities(), model => {
                        // only delete items which are not locked
                        // We disable KB deletion of nodes, while still allowing deletion of links etc.
                        // (by checking for existance of getNodeType)
                        if (!model.isLocked() && model && (model.getNodeType == undefined)) {
                            model.remove();
                        }
                    });
                    this.engine.repaintCanvas();
                }
            }
        });
    }
}