import { DeserializeEvent } from '@projectstorm/react-canvas-core';
import { DefaultLinkModel } from '@projectstorm/react-diagrams';

export class SynthLinkModel extends DefaultLinkModel {
    _strength: number;

    constructor() {
        super({
            type: 'default',
        });
        this._strength = 1.0;
    }

    serialize() {
        let params = {
            strength: this._strength,
        };
        return Object.assign({}, super.serialize(), {
            params: params,
        });
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this._strength = event.data.params.strength;
    }

    getStrength(): number {
        return this._strength;
    }

    setStrength(s: number) {
        this._strength = s;
        this.fireEvent({}, 'paramChanged');
    }
}