import { DefaultNodeModel } from '@projectstorm/react-diagrams';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';

import { SynthPortModel } from './SynthPortModel';

export interface OscillatorNodeParams {
    frequency: number;
}

export interface VcfNodeParams {
    cutoff: number;
}

export interface VcaNodeParams {
    level: number;
}

export class DefaultSynthNodeModel extends DefaultNodeModel {
    getNodeType() {
        return "default";
    }
}


export class GenericSynthNodeModel extends DefaultNodeModel {
    _params: Map<string, any>;
    _type: string;

    constructor(name: string, type: string, color: string, ports, params) {
        super({
            name: name,
            type: type,
            color: color,
        });

        ports.in.forEach(portName => {
            this.addPort(new SynthPortModel(true, portName, portName));
        });
        ports.out.forEach(portName => {
            this.addPort(new SynthPortModel(false, portName, portName));
        });

        this._params = new Map();
        params.forEach(({ name, initValue }) => {
            this._params.set(name, initValue);
        });
    }

    getNodeType() {
        return this._type;
    }

    serialize() {
        let params = {};
        for (let [key, value] of this._params.entries()) {
            params[key] = value;
        }
        return Object.assign({}, super.serialize(), {
            params: params,
        });
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this._params = new Map();
        Object.keys(event.data.params).forEach(key => {
            this._params.set(key, event.data.params[key]);
        })
    }

    setParam(name: string, value: any) {
        this._params.set(name, value);
        this.fireEvent({}, 'paramChanged');
    }

    getParam(paramName: string) {
        return this._params.get(paramName);
    }

    getAllParams() {
        return this._params;
    }
}


function buildOscillatorNodeModel() {
    let model = new GenericSynthNodeModel('Oscillator', 'oscillator', 'rgb(192,0,255)',
        {
            in: ['Frequency'],
            out: ['Sine', 'Saw', 'Square'],
        },
        [
            { name: 'frequency', initValue: 440.0 },
        ]
    );
    model.setFrequency = (f: number) => { return model.setParam('frequency', f) };
    model.getFrequency = () => { return model.getParam('frequency') };
    return model;
}


function buildLfoNodeModel() {
    let model = new GenericSynthNodeModel('Low Frequency Oscillator', 'lfo', 'rgb(0,192,192)',
        {
            in: ['Frequency'],
            out: ['Out'],
        },
        [
            { name: 'frequency', initValue: 1.0 },
        ]
    );
    model.setFrequency = (f: number) => { return model.setParam('frequency', f) };
    model.getFrequency = () => { return model.getParam('frequency') };
    return model;
}


function buildEnvelopeNodeModel() {
    let model = new GenericSynthNodeModel('Envelope', 'envelope', 'rgb(0,192,192)',
        {
            in: ['Gate'],
            out: ['Out'],
        },
        [
            { name: 'attack', initValue: 0.5 },
            { name: 'decay', initValue: 0.5 },
            { name: 'sustain', initValue: 0.5 },
            { name: 'release', initValue: 0.5 },
        ]
    );
    return model;
}


function buildVcfNodeModel() {
    let model = new GenericSynthNodeModel('Low Pass Filter', 'vcf', 'rgb(0,192,255)',
        {
            in: ['Audio In', 'Cutoff'],
            out: ['Audio Out'],
        },
        [
            { name: 'cutoff', initValue: 440.0 },
        ]
    );
    model.setCutoff = (c: number) => { return model.setParam('cutoff', c) };
    model.getCutoff = () => { return model.getParam('cutoff') };
    return model;
}


function buildVcaNodeModel() {
    let model = new GenericSynthNodeModel('Volume', 'vca', 'rgb(0,192,0)',
        {
            in: ['Audio In', 'Volume'],
            out: ['Audio Out'],
        },
        [
            { name: 'level', initValue: 1.0 },
        ]
    );
    model.setLevel = (l: number) => { return model.setParam('level', l) };
    model.getLevel = () => { return model.getParam('level') };
    return model;
}


function buildNoteInModel() {
    return new GenericSynthNodeModel('Note Input', 'note_in', 'rgb(255, 180, 40)',
        {
            in: [],
            out: ['Frequency', 'On/Off']
        },
        []
    );
}


function buildReverbModel() {
    return new GenericSynthNodeModel('Reverb', 'reverb', 'rgb(50, 50, 50)',
        {
            in: ['Audio In'],
            out: ['Audio Out']
        },
        [
            { name: 'decay', initValue: 1.0 },
            { name: 'preDelay', initValue: 0.01 },
        ]
    );
}


function buildAddNodeModel() {
    return new GenericSynthNodeModel('+', 'add', 'rgb(128,128,128)',
        {
            in: ['#1', '#2'],
            out: ['Sum'],
        },
        [
            { name: 'base', initValue: 0.0 },
        ]
    );
}


function buildMutliplyNodeModel() {
    return new GenericSynthNodeModel('x', 'multiply', 'rgb(128,128,128)',
        {
            in: ['In'],
            out: ['Product'],
        },
        [
            { name: 'factor', initValue: 1.0 },
        ]
    );
}


function buildAudioOutNodeModel() {
    return new GenericSynthNodeModel('Audio Out', 'out', 'rgb(192,50,0)',
        { in: ['In'], out: [] }, []);
}


export function buildNodeModel(type: string) {
    switch (type) {
        case 'add':
            return buildAddNodeModel();
            break;
        case 'multiply':
            return buildMutliplyNodeModel();
            break;
        case 'oscillator':
            return buildOscillatorNodeModel();
            break;
        case 'lfo':
            return buildLfoNodeModel();
            break;
        case 'envelope':
            return buildEnvelopeNodeModel();
            break;
        case 'vca':
            return buildVcaNodeModel();
            break;
        case 'out':
            return buildAudioOutNodeModel();
            break;
        case 'vcf':
            return buildVcfNodeModel();
            break;
        case 'note_in':
            return buildNoteInModel();
            break;
        case 'reverb':
            return buildReverbModel();
            break;
        default:
            return new DefaultSynthNodeModel();
    }
}