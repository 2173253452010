// Code tracking notes that are input (typically via keyboard or midi)

export class MonoSynthNoteTracker {
    private _notes: Array<number>;
    private _lastNote: number;

    constructor() {
        this._notes = [];
        this._lastNote = 60;
    }

    noteOn(n: number) {
        this._notes.push(n);
        this._lastNote = n;
    }

    noteOff(n: number) {
        this._notes = this._notes.filter(e => e != n);
        if (this._notes.length > 0) {
            this._lastNote = this._notes[this._notes.length - 1];
        }
    }

    notesArePlaying(): boolean {
        return this._notes.length > 0;
    }

    getCurrentNote(): number {
        return this._lastNote;
    }
};